import {
    BASIC_COMPOSITION_TYPE,
    IS_NEXT_IN_LIST,
    ISCHEAPEST,
    ISEXPENSIVE,
} from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { t } from "i18next";
import { produce } from "immer";
import { get as getObjectWithPath, set, unset } from "lodash";

import { RequirementToast, successAddToast } from "@components/common/Toast";

import {
    calculateRemovedProductPrice,
    getObjectPrice,
    getStepDetails,
    getStepPrice,
    maxPriceWithoutSpecialPrice,
    minPriceWithoutSpecialPrice,
    minPriceWithStepPrice,
    setObj,
} from "../../Helpers";

export const initialState = {
    item: {
        iuudorder: "",
        iuud: "",
        name: "",
        quantity: 1,
        price: 0,
        urlImage: "",
        iuudworkflow: "",
        categoryParent: "",
        workflow: {},
        shopCart: {},
        isModification: false,
        selectedOptions: [],
        totalFidelity: 0,
    },
    isModification: false,
    productBeforeUpdate: {},
    isInternalModification: false,
};

export const orderWorkflowItemSlice = createSlice({
    name: "orderWorkflowItemSlice",
    initialState,
    reducers: {
        addToOrderworkflow: (state, { payload }) => {
            state.item = {
                ...payload,
                defaultPrice: state.isModification
                    ? payload.defaultPrice
                    : payload.price,
                totalFidelity: payload.totalFidelity,
            };

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                    selectedIndexOptions: [],
                })
            );
        },

        addWorkFlowItemShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                const isAutoPermutation =
                    (state.isInternalModification || state.isModification) &&
                    action.payload.autoPermutation &&
                    action.payload.type !== BASIC_COMPOSITION_TYPE &&
                    action.payload.workflow.length < 2;

                const isAutoPermutationInComposition =
                    (state.isInternalModification || state.isModification) &&
                    action.payload.autoPermutation &&
                    action.payload.type !== BASIC_COMPOSITION_TYPE &&
                    action.payload.workflow.length > 1;

                if (isAutoPermutation) {
                    const stepPathComposition = [...path];

                    stepPathComposition.length = path.length - 1;

                    const stepPath = [...stepPathComposition];
                    stepPath.length = stepPathComposition.length - 1;

                    let stepDataObject = stepPathComposition.reduce(
                        (prev, key) => {
                            return prev[key] || {};
                        },
                        draftState.shopCart
                    );
                    const filteredData = Object.keys(stepDataObject)
                        .filter((key) => key !== BASIC_COMPOSITION_TYPE)
                        .reduce((prev, currKey) => {
                            return {
                                ...prev,
                                [currKey]: stepDataObject[currKey],
                            };
                        }, {});

                    if (Object.keys(filteredData).length > 0) {
                        const productToRemove = Object.values(filteredData)[0];

                        const removedProductPrice =
                            calculateRemovedProductPrice(productToRemove);

                        draftState.price =
                            draftState.price - removedProductPrice;
                        const itemPathToRemove = productToRemove.path.map(
                            (elm) => elm.name
                        );
                        RequirementToast(t("Deleted product"));
                        unset(draftState.shopCart, itemPathToRemove);
                    }

                    if (
                        action.payload.haveWorkflow === false &&
                        action.payload.haveBasicComposition === false
                    ) {
                        successAddToast(t("Product added"));
                    }
                }
                if (isAutoPermutationInComposition) {
                    const stepPathComposition = [...path];

                    stepPathComposition.length = path.length - 1;

                    let stepDataObject = stepPathComposition.reduce(
                        (prev, key) => {
                            return prev[key] || {};
                        },
                        draftState.shopCart
                    );
                    const filteredData = Object.keys(stepDataObject)
                        .filter((key) => key !== BASIC_COMPOSITION_TYPE)
                        .reduce((prev, currKey) => {
                            return {
                                ...prev,
                                [currKey]: stepDataObject[currKey],
                            };
                        }, {});

                    if (Object.keys(filteredData).length > 0) {
                        const productToRemove = Object.values(filteredData)[0];

                        draftState.price =
                            draftState.price - productToRemove.price;
                        const itemPathToRemove = productToRemove.path.map(
                            (elm) => elm.name
                        );

                        unset(draftState.shopCart, itemPathToRemove);
                    }
                }
                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                const stepPath = path[0];

                let sumFidelityPayload = 0;
                if (path[path.length - 1] === BASIC_COMPOSITION_TYPE) {
                    sumFidelityPayload = Object.values(
                        action.payload?.compositions
                    ).reduce((prev, curr) => {
                        return prev + curr.totalFidelity;
                    }, 0);
                } else {
                    sumFidelityPayload = action.payload.totalFidelity;
                }

                if (
                    Object.keys(objectToModify).length === 0 ||
                    path[0] === BASIC_COMPOSITION_TYPE
                ) {
                    setObj(draftState.shopCart, path.join("#"), action.payload);
                } else {
                    setObj(draftState.shopCart, path.join("#"), action.payload);
                    objectToModify.workflow = action.payload.workflow;
                    objectToModify.quantity = +objectToModify.quantity + 1;
                }
                // ! ici on ajoute ordre etape pour organise shopcart en affichage panier

                if (
                    draftState.shopCart[stepPath].stepRank === undefined &&
                    path[0] !== BASIC_COMPOSITION_TYPE
                ) {
                    draftState.shopCart[stepPath].stepRank =
                        action.payload.stepRank;
                }
                draftState.price = draftState.price + action.payload.price;
                draftState.totalFidelity =
                    draftState.totalFidelity + sumFidelityPayload;
                if (
                    action.payload.haveBasicComposition === false &&
                    action.payload.haveWorkflow === false &&
                    action.payload.workflow[action.payload.workflow.length - 1]
                        .step ===
                        action.payload.workflow[
                            action.payload.workflow.length - 1
                        ].index &&
                    !isAutoPermutation &&
                    !isAutoPermutationInComposition
                ) {
                    successAddToast(t("Product added"));
                }
            });

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                    selectedIndexOptions: [],
                })
            );
        },
        restoreItemComposition: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.stepPath;
                const stepPathComposition = [...path];
                stepPathComposition.length = 2;

                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                setObj(
                    draftState.shopCart,
                    path.join("#"),
                    action.payload.oldComposition
                );
                const modifiedObjectPrice = getObjectPrice(
                    "price",
                    objectToModify
                );
                const oldObjectPrice = getObjectPrice(
                    "price",
                    action.payload.oldComposition
                );

                let stepComposition = stepPathComposition.reduce(
                    (prev, key) => {
                        return prev[key] || {};
                    },
                    draftState.shopCart
                );

                draftState.price =
                    draftState.price - modifiedObjectPrice + oldObjectPrice;

                if (Object.keys(stepComposition).length === 0) {
                    unset(draftState.shopCart, stepPathComposition[0]);
                }
                state.item = draftState;
            });

            localStorage.setItem(
                "currentItemPriceOptions",
                JSON.stringify({
                    selectedOptions: [],
                    selectedOptionsUuid: [],
                    newPrice: 0,
                    tva: 0,
                    selectedIndexOptions: [],
                })
            );
        },

        removeFromWorkFlowItemShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.product.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });

                const stepPathComposition = [...path];

                stepPathComposition.length = path.length - 1;
                const stepPath = [...stepPathComposition];
                stepPath.length = stepPathComposition.length - 1;

                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(objectToModify).length === 0) {
                    setObj(
                        draftState.shopCart,
                        path.join("#"),
                        action.payload.product
                    );
                    draftState.price =
                        draftState.price - action.payload.product.price;
                } else {
                    objectToModify.quantity = +objectToModify.quantity - 1;
                    let objectPrice = objectToModify.price;
                    if (objectToModify?.haveWorkflow) {
                        objectPrice = getObjectPrice("price", objectToModify);
                    }
                    draftState.price = draftState.price - objectPrice;
                    draftState.totalFidelity =
                        draftState.totalFidelity -
                        action.payload.product.totalFidelity;
                }

                if (objectToModify.quantity === 0) {
                    unset(draftState.shopCart, path);
                }
                let stepComposition = stepPathComposition.reduce(
                    (prev, key) => {
                        return prev[key] || {};
                    },
                    draftState.shopCart
                );

                const stepItemMenu = getObjectWithPath(
                    draftState.shopCart,
                    stepPath.join(".")
                );

                if (Object.keys(stepComposition).length === 0) {
                    if (stepItemMenu.type === undefined) {
                        // stepPath.length = stepPath.length - 1;
                        //! to verify if many nested
                        unset(draftState.shopCart, stepPath);
                    }
                    getStepDetails(stepComposition);
                    state.item = draftState;
                    return;
                }

                let newData = {};
                let menuPrice = 0;

                const isBasicCompositionStep =
                    Object.keys(stepComposition).length === 1 &&
                    Object.values(stepComposition)[0].type ===
                        "composition de base";
                if (!isBasicCompositionStep) {
                    if (objectToModify.withSpecialPrice) {
                        const stepPrice = getStepPrice(stepComposition);

                        menuPrice = draftState.price - stepPrice;

                        switch (objectToModify.StepPriceRule) {
                            case ISCHEAPEST:
                                newData = minPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                                break;
                            case ISEXPENSIVE:
                                newData = maxPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                                break;
                            case IS_NEXT_IN_LIST:
                                newData = minPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                                break;
                            default:
                                newData = minPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                        }

                        stepComposition = { ...stepComposition, ...newData };
                        const newStepPrice = getStepPrice(stepComposition);
                        menuPrice += newStepPrice;
                        draftState.price = menuPrice;

                        set(
                            draftState.shopCart,
                            stepPathComposition.join("."),
                            stepComposition
                        );
                    } else if (objectToModify.withStepPrice) {
                        switch (objectToModify.StepPriceRule) {
                            case ISCHEAPEST:
                                newData = minPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                                break;
                            case ISEXPENSIVE:
                                newData = maxPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );
                                break;
                            case IS_NEXT_IN_LIST:
                                newData = minPriceWithoutSpecialPrice(
                                    "withSpecialPrice",
                                    "specialPrice",
                                    stepComposition
                                );

                                break;
                            default:
                                newData =
                                    minPriceWithStepPrice(stepComposition);
                        }

                        stepComposition = { ...stepComposition, ...newData };

                        set(
                            draftState.shopCart,
                            stepPathComposition.join("."),
                            stepComposition
                        );
                    }
                }
                getStepDetails(stepComposition);
                draftState.totalFidelity =
                    draftState.totalFidelity -
                    action.payload.product.totalFidelity;
            });
        },
        deleteBasicCompositionFromShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                path.length > 0 && unset(draftState.shopCart, path);
            });
        },
        deleteWorkFlowStepShopCart: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                let path = action.payload.path.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });
                path.pop();
                let objectToModify = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(objectToModify).length > 0) {
                    unset(draftState.shopCart, path);
                }
                let stepComposition = path.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(stepComposition).length === 0) {
                    unset(draftState.shopCart, path);
                }
                const menuItemPrice = getObjectPrice("price", objectToModify);

                draftState.price = draftState.price - menuItemPrice;
                draftState.totalFidelity =
                    draftState.totalFidelity - action.payload.totalFidelity;
            });
        },

        setiuudWorkflow: (state, action) => {
            state.item.iuudworkflow = action.payload;
        },
        setIsModification: (state, action) => {
            state.isModification = action.payload;
        },
        setWorkFlowItemComment: (state, action) => {
            state.item = produce(state.item, (draftState) => {
                const { path, comment } = action.payload;
                let pathItem = path?.map((elm) => {
                    return elm?.name !== undefined ? elm.name : elm;
                });

                let objectToModify = pathItem.reduce((prev, key) => {
                    return prev[key] || {};
                }, draftState.shopCart);

                if (Object.keys(objectToModify).length > 0) {
                    objectToModify.comment = comment;
                }
            });
        },

        ClearWorkflowItem: () => initialState,
        setProductBeforeUpdate: (state, action) => {
            state.productBeforeUpdate = action.payload;
        },
        setIsInternalModification: (state, action) => {
            state.isInternalModification = action.payload;
        },
    },
});

export const {
    addWorkFlowItemShopCart,
    removeFromWorkFlowItemShopCart,
    addToOrderworkflow,
    ClearWorkflowItem,
    deleteWorkFlowStepShopCart,
    deleteBasicCompositionFromShopCart,
    setiuudWorkflow,
    setIsModification,
    setWorkFlowItemComment,
    setProductBeforeUpdate,
    restoreItemComposition,
    setIsInternalModification,
} = orderWorkflowItemSlice.actions;

export default orderWorkflowItemSlice.reducer;
